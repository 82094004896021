<template>
  <div class="amap-wrapper">
    <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult" />
    <el-amap
      ref="map"
      class="amap-box"
      :style="{'width':width,'height':height}"
      :vid="'amap-vue-search'"
      :center="center"
      :zoom="zoom"
      :events="events"
    >
      <el-amap-marker v-for="(marker,index) in markers" :key="index" :position="marker" />
    </el-amap>
  </div>
</template>

<script>
export default {
  name: 'MapG',
  props: {
    width: {
      type: String || Number,
      default: '90%'
    },
    height: {
      type: String || Number,
      default: '500px'
    }
  },
  data() {
    return {
      zoom: 12,
      center: [116.396304, 39.909847],
      events: {
        'click': (e) => {
          const that = this
          if (!e) {
            return
          }
          const positionArr = []
          positionArr.push(e.lnglat.lng)
          positionArr.push(e.lnglat.lat)
          this.markers = []
          this.markers.push(positionArr)
          // 这里通过高德 SDK 完成。
          // eslint-disable-next-line no-undef
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress(positionArr, function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                console.log(result)
                const detailObj = {
                  address: result.regeocode.formattedAddress,
                  province: result.regeocode.addressComponent.province,
                  city: result.regeocode.addressComponent.city || result.regeocode.addressComponent.province,
                  country: result.regeocode.addressComponent.district,
                  longitude: positionArr[0],
                  latitude: positionArr[1]
                }
                that.$emit('getInfo', detailObj)
              }
            }
          })
        }
      },
      markers: [],
      searchOption: {
        city: '',
        citylimit: false
      }
    }
  },
  methods: {
    mapInit(lng, lat) {
      if (lng && lat) {
        this.center = [lng, lat]
        this.markers = [this.center]
      } else {
        this.center = [116.396304, 39.909847]
      }
    },
    onSearchResult(pois) {
      // let latSum = 0
      // let lngSum = 0
      const that = this
      console.log(pois)
      if (pois.length > 0) {
        const marker = [pois[0].lng, pois[0].lat]
        this.markers = [marker]
        // eslint-disable-next-line no-undef
        var geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: 'all'
        })
        this.center = marker
        geocoder.getAddress(marker, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.regeocode) {
              console.log(result)
              const detailObj = {
                address: result.regeocode.formattedAddress,
                province: result.regeocode.addressComponent.province,
                city: result.regeocode.addressComponent.city || result.regeocode.addressComponent.province,
                country: result.regeocode.addressComponent.district,
                longitude: marker[0],
                latitude: marker[1]
              }
              that.$emit('getInfo', detailObj)
            }
          }
        })
        // pois.forEach(poi => {
        //   const { lng, lat } = poi
        //   lngSum += lng
        //   latSum += lat
        //   this.markers.push([poi.lng, poi.lat])
        // })
        // const center = {
        //   lng: lngSum / pois.length,
        //   lat: latSum / pois.length
        // }
      }
    }
  }
}
</script>
<style scoped>
.amap-wrapper {
  text-align: right;
  position: relative;
}
.amap-box{
  width: 90%;
  margin-left: 5%;
}
.search-box {
  position: absolute;
  top: 15px;
  left: 6%;
}
</style>
